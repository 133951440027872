export const getValue = (dict, key) => {
    if (dict.hasOwnProperty(key)) {
      if (typeof(dict[key]) == 'undefined') {
        return '';
      } else if (dict[key] == null) {
        return '';
      } else {
        return dict[key];
      }
    } else {
      return '';
    }
  }
  